// utils/config.js

// Save a value in sessionStorage
export const setConfig = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

// Get a value from sessionStorage
export const getConfig = (key: string, defaultValue?: any) => {
  const strRep = sessionStorage.getItem(key);

  return strRep ? JSON.parse(strRep) : defaultValue;
};

// Remove a value from sessionStorage
export const clearConfig = (key: string) => {
  sessionStorage.removeItem(key);
};

export default {
  getConfig,
  setConfig,
  clearConfig
}
