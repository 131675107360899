import Keycloak from "keycloak-js";
import { User } from "../requests/requests";
import axios, { AxiosInstance } from "axios";
import { undefinedIfEmpty } from "../util/text";

export type getIdsResultType = {
  pmId: string;
};

export class BackendApi {
  private kc: Keycloak;
  private url: string;
  private client: AxiosInstance;
  private isBackendOnline = false;

  constructor({ url, keycloak }: { url: string; keycloak: Keycloak }) {
    this.kc = keycloak;
    this.url = url;
    this.client = axios.create({ baseURL: url });
  }

  private headers() {
    return {
      Authorization: `Bearer ${this.kc.token}`,
    };
  }

  async checkBackendOnline() {
    try {
      const response = await this.client.get("/");
      if (response.status === 200) {
        this.isBackendOnline = true;
      } else {
        this.isBackendOnline = false;
      }
    } catch (e) {
      this.isBackendOnline = false;
    }
    return this.isBackendOnline;
  }

  async OrgIds(fhirOrgIds: string[]) {
    if (!fhirOrgIds || fhirOrgIds.length === 0) return {};
    const response = await this.client.get("/admin/org-ids", {
      headers: this.headers(),
      params: { fhirOrgIds },
    });
    return response.data;
  }

  async getIds(user: User): Promise<getIdsResultType[]> {
    const pmId = user.pm?.baseData?.id;
    if (!pmId) return [];
    const response = await this.client.get("/patient/emails-and-ids", {
      headers: this.headers(),
      params: { pmId },
    });
    return response.data;
  }

  async getConsentsByUser(
    user: User,
    {
      year,
      month,
    }: {
      year?: number;
      month?: number;
    } = {}
  ) {
    const pmId = user.pm?.baseData?.id;
    if (!pmId) return [];

    // if no time span is specified, the current data from Fhir are requested, else from the consents db.
    if (!year && !month) {
      const response = await this.client.get("/patient/consents", {
        headers: this.headers(),
        params: { pmId },
      });
      const patientConsents = response.data;
      return patientConsents;
    } else {
      const response = await this.client.get("/admin/consents-by-patient", {
        headers: this.headers(),
        params: {
          pmId,
          year,
          month,
        },
      });
      const patients = response.data.data;
      return patients;
    }
  }

  async getConsentCounts(
    {
      organization,
      year,
      month,
    }: {
      organization?: string | number;
      year?: number;
      month?: number;
    } = {},
    {
      groupBy,
    }: {
      groupBy?: { organization?: boolean; year?: boolean; month?: boolean };
    } = {}
  ) {
    const params: any = { organization, year, month };
    if (groupBy?.organization) params["show-organization"] = true;
    if (groupBy?.year) params["show-year"] = true;
    if (groupBy?.month) params["show-month"] = true;
    const response = await this.client.get("/admin/historic-consents", {
      headers: this.headers(),
      params,
    });
    return response.data;
  }

  async deleteUser(user: User, { partialDelete = false } = {}) {
    const response = await this.client.delete("/patient/user-data", {
      headers: this.headers(),
      params: {
        pmId: user.pm.baseData.id,
        partialDelete,
      },
    });
    return response.data;
  }

  async getValidUsersWithoutCases(listFhirIds = false): Promise<NoCasesDto[]> {
    const response = await this.client.get("/admin/listMissingCases", {
      headers: this.headers(),
      params: { listFhirIds },
    });
    return response.data;
  }

  async fixPatientWithoutCases(
    pmId?: string,
    fhirId?: string,
    options: { title?: string; description?: string } = {}
  ): Promise<fixMissingCasesDto[]> {
    const params: any = {
      pmId,
      fhirId,
      title: undefinedIfEmpty(options.title),
      description: undefinedIfEmpty(options.description),
    };

    const response = await this.client.post(
      "/admin/fixMissingCases",
      undefined,
      {
        headers: this.headers(),
        params,
      }
    );
    return response.data;
  }

  async sendForgotEmail(email: string) {
    const response = await this.client.post(
      "/patient/send-forgot-email",
      { email },
      {
        headers: this.headers(),
      }
    );
    return { response }
  }
  async sendEmailFromTemplate(email: string, template: number, variables: { [k: string]: any }) {
    const response = await this.client.post(
      "/admin/send-email-from-template",
      { email, template, variables },
      {
        headers: this.headers(),
      }
    );
    return { response }
  }

}

export interface fixMissingCasesDto {
  patientPmId: string;
  patientFhirId: string;
  response: {
    msg: string;
  };
}

export interface NoCasesDto {
  pmId: string;
  fhirId?: string;
}
