import { Button, Divider, Form, Input } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../context/context';
import ShowResponse from './ShowResponse';

export default function ForgotPass() {
  const { api } = useContext(Context);
  const [email, setEmail] = useState<string>()
  const [response, setResponse] = useState<any>()
  const [error, setError] = useState<string>()

  async function sendEmail() {
    if (email) {
      try {
        await api?.sendForgotEmail(email)
        setResponse('Email sent.');
      } catch (e: any) {
        setError(e.toString());
      }

    }
  }

  return (
    <div>
      <Divider><h4>Send forgot E-Mail</h4></Divider>
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
        <Form.Item
          label="E-mail"
          className="user-input"
          colon
        >
          <Input
            value={email}
            autoComplete="off"
            required={true}
            style={{ width: 300 }}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Button type="primary" htmlType="button" onClick={sendEmail} disabled={!email}>
          Send e-mail
        </Button>
      </div>
      <ShowResponse isError={!!error}>{response || error}</ShowResponse>

    </div>
  )
}


