export function jsonSyntaxHighlight(json: any): string {
  if (json === undefined) return "";
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match: any) {
      var cls = "json-number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "json-key";
        } else {
          cls = "json-string";
        }
      } else if (/true|false/.test(match)) {
        cls = "json-boolean";
      } else if (/null/.test(match)) {
        cls = "json-null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

export function capitalize(string: string) {
  const firstLetter = string.substring(0, 1);
  const rest = string.substring(1);
  const capitalized = firstLetter.toUpperCase() + rest;
  return capitalized;
}

export function convertNameToFileParticle(name: string): string {
  return name.toLowerCase().split(" ").join("_");
}

export function convertBooleanToString(val: boolean): string {
  if (typeof val !== "boolean") {
    return val;
  }
  return val ? "true" : "false";
}

export function isEmptyText(val: string | undefined | null): boolean {
  return !!val && val.trim().length > 0;
}
export function undefinedIfEmpty(
  val: string | undefined | null
): string | undefined {
  return isEmptyText(val) ? undefined : (val as string);
}

export const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}
