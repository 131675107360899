import { Button, Divider, Form, Input, Radio, RadioChangeEvent, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../context/context';
import ShowResponse from './ShowResponse';

type TemplateInfo = {
  label: string;
  id: number;
  variables: string[];
}

const TEMPLATES: { [k: string]: TemplateInfo } = {
  onboarding: {
    label: "Onboarding Charite",
    id: 6367444,
    variables: ["tmpUserName", "tmpPassword", "envDomain"] //add properly
  },
  invitation: {
    label: "Doctor invite",
    id: 6368012,
    variables: ["userName", "tmpPassword", "envDomain"]
  },
  forgot_password: {
    label: "Forgot Password",
    id: 6450367,
    variables: ["envDomain", "token"]
  },
  password_changed: {
    label: "Password got changed",
    id: 6373677,
    variables: ["firstName", "lastName"]
  }
}

export default function SendEmailTemplate() {
  const { api } = useContext(Context);
  const [email, setEmail] = useState<string>()
  const [response, setResponse] = useState<any>()
  const [error, setError] = useState<string>()
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateInfo>();
  const [variables, setVariables] = useState<{ [k: string]: any }>({});


  function selectTemplate(e: RadioChangeEvent) {
    setSelectedTemplate(TEMPLATES[e.target.value])
  }

  function changeVariable(name: string, val: any) {
    const varCopy = { ...variables };
    varCopy[name] = val;
    setVariables(varCopy);
  }


  async function sendEmail() {
    if (email && selectedTemplate) {
      try {
        await api?.sendEmailFromTemplate(email, selectedTemplate.id, variables)
        setResponse('Email sent.');
      } catch (e: any) {
        setError(e.toString());
      }

    }
  }

  useEffect(() => {
    if (selectedTemplate) {
      setVariables(Object.fromEntries(selectedTemplate.variables.map(v => [v, undefined])));
    }
  }, [selectedTemplate])

  return (
    <div>
      <Divider><h4>Send E-Mail from template</h4></Divider>
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
        <Form.Item
          label="E-mail"
          className="user-input"
          colon
        >
          <Input
            value={email}
            autoComplete="off"
            required={true}
            style={{ width: 300 }}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Button type="primary" htmlType="button" onClick={sendEmail} disabled={!email}>
          Send e-mail
        </Button>
      </div>
      <ShowResponse isError={!!error}>{response || error}</ShowResponse>
      <div style={{
        width: 400, margin: 'auto',
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10,
        padding: 20, borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid', backgroundColor: 'white',
        borderRadius: 10
      }}>
        <div>
          <Radio.Group onChange={selectTemplate}>
            {Object.entries(TEMPLATES).map(([name, info]) => (
              <Radio key={name} value={name}>{info.label}</Radio>
            ))}
          </Radio.Group>
        </div>
        <div>
          {Object.entries(variables).map(([name, val]) => (
            <Variable key={`${selectedTemplate?.id}_${name}`} value={val} name={name} onChange={(v) => changeVariable(name, v)} />
          ))}
        </div>
      </div>
    </div>
  )
}

type VariableProps<T extends string | number> = {
  value: T;
  name: string;
  onChange: (v: T) => unknown
}
function Variable<T extends string | number>({ value, name, onChange }: VariableProps<T>) {
  const [val, setVal] = useState(value)
  return (
    <Form.Item
      label={name}
      className="user-input"
      colon
    >
      <Input
        value={val}
        autoComplete="off"
        style={{ width: 300 }}
        onChange={(e) => { setVal(e.target.value as T); onChange(e.target.value as T) }}
      />
    </Form.Item>
  )
}
