import local from "./local.json";
import dev from "./dev.json";
import stage from "./stage.json";
import prod from "./prod.json";
import teamedio from "./teamedio.json";
import * as buildInfoLocal from "../build.json";
import { log } from "../util/logger";
import SessionStorage from "../util/SessionStorage";

const SESSION_STORE_KEY = 'config'
type BuildInfoType = typeof buildInfoLocal;
const allConfs: any = {
  local,
  dev,
  stage,
  prod,
  teamedio,
};

export const CONFIGURED_ENVS = Object.keys(allConfs);

export interface ConfigType {
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  url: {
    admin: string;
    message: string;
  };
  env?: string;
  buildInfo?: BuildInfoType;
  wasChanged: () => boolean;
}


let config: ConfigType;

export function defaultConfig(): ConfigType {
  const buildInfo = buildInfoLocal;
  // do not change anything, just get the default config
  const config = allConfs[buildInfo.env.APP_ENV];
  config.buildInfo = buildInfo;
  return config;
}

async function getBuildInfo() {
  try {
    const response = await fetch("/build.json");
    // fails if env is local, then config assigned in catch block
    const data = await response.json();
    return data;
  } catch (err) {
    log("Could not load build.json, env is local.");
    return buildInfoLocal;
  }
}

export async function getConfig() {
  if (config) return config;
  let env, buildInfo;

  buildInfo = await getBuildInfo();
  env = SessionStorage.getConfig(SESSION_STORE_KEY, {}).env
  if (env) {
    log('overriding from session: ', env)
  } else {
    env = buildInfo.env.APP_ENV
  }
  config = allConfs[env];
  config.env = env;
  config.buildInfo = buildInfo;
  return config;
}

export function changeConfig(env: string): ConfigType {
  if (!allConfs[env]) {
    throw new Error(`Could not find env ${env}`);
  }
  console.log('Will change to: ', env)
  config = allConfs[env];
  config.env = env;
  SessionStorage.setConfig(SESSION_STORE_KEY, { env })
  return config;
}
